.quill > * {
    border-color: #3b3b3b !important;
    color: inherit !important;
}
.quill > .ql-toolbar {
    /* border radius of the toolbar */
    border-radius: 4px 4px 0 0;
}
.quill > .ql-container {
    /* border radius of the container and for font size*/
    font-size: inherit;
    /*border-radius: 0 0 10px 10px;*/
    min-height: 500px;
    background-color: #121212;

}
.ql-toolbar.ql-snow .ql-picker-label {
    color: inherit !important;
    opacity: 0.76;
}
.ql-snow .ql-picker {
    color: inherit !important;
}
.quill > .ql-container > .ql-editor.ql-blank::before {
    /* for placeholder */
    color: inherit;
}
/*.ql-snow.ql-toolbar button svg {*/
/*    opacity: 0.76;*/
/*    color: currentColor;*/
/*}*/
.ql-snow.ql-toolbar button {
    opacity: 0.76;
    color: currentColor;
}
.ql-snow .ql-stroke {
    /* for the border of the editor */
    stroke: currentColor !important;
}
.ql-snow .ql-fill {
    /* for the bg color */
    fill: currentColor !important;
}
.ql-picker-item {
    /* for dropdown */
    color: #444 !important;
}
.ql-editor {
    min-height: inherit !important;

}

.content-img img {
    max-width: 100%;
    object-fit: contain;
}
