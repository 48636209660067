html, body {
  height: 100vh;
  margin: 0;
  overflow-y: auto;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input, textarea, select {
  font-size: 16px; /* 사파리에서 자동 확대 방지 */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

.scrollBar {
  display: flex;
  overflow-x: scroll;
  transform: rotate(180deg);
  flex-direction: row-reverse;
  width: 100%;
}

.scrollBar::-webkit-scrollbar {
  height: 8px;
}
.scrollBar::-webkit-scrollbar-track-piece {
  background: white;
  border: #c1c1c1;
  border-radius: 0.5rem;
}
.scrollBar::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  background: #c1c1c1;
}
/*.scrollBar::-webkit-scrollbar-button:end:increment {*/
/*  height: 50%;*/
/*  display: block;*/
/*  background: transparent;*/
/*}*/

.popup-scroll::-webkit-scrollbar {
  width: 7px;
}

.popup-scroll::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  background: #c1c1c1;
}

.list-scroll::-webkit-scrollbar {
  width: 5px;
}

.list-scroll::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  background: #c1c1c1;
}

/*.list-scroll::-webkit-scrollbar-button:end:increment {*/
/*  background: transparent;*/
/*  display: block;*/
/*}*/

.pie-charter {
  height: 700px;
}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
  cursor: pointer;
}

.MuiTable-root tr td:last-child div {
  justify-content: flex-end;
}

/* App */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}