@import url("constants/theme/colors.css");

@font-face {
  font-family: "Calibri";
  src: url("./assets/fonts/Calibri.woff") format("woff");
  unicode-range: U+0020-007F;
}
@font-face {
  font-family: "MalgunGodic";
  src: url("./assets/fonts/MalgunGodic.woff") format("woff");
  unicode-range: U+AC00-D7A3;
}



/* Table */
.ant-table-expanded-row > .ant-table-cell {
  padding: 0 !important;
}
.ant-table-expanded-row table .ant-table-selection-column {
  padding-left: 105px !important;
}
.ant-table-expanded-row .ant-table {
  margin: 0 !important;
}



/* Tabs */
.ant-tabs-nav {
  position: sticky !important;
  top: 0 !important;
  z-index: 9 !important;
  margin: 0 !important;
  padding: 0 24px !important;
  background: white !important;
}



/* Menu */
.ant-menu-root {
  width: 100% !important;
}

/* Steps */
.ant-steps-item,
.ant-steps-item-icon {
  padding: 0 !important;
  margin: 0 !important;
}

.ant-steps-item-title {
  width: 0 !important;
  margin-left: -12px;
}

/* Dropdown */
.ant-dropdown > .ant-dropdown-menu {
  /*position: relative;*/
  max-width: 208px;
  max-height: 320px;
  overflow-y: auto;
}
.ant-dropdown-menu-item {
  width: 100%;
}
.ant-dropdown-menu-title-content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ant-dropdown-menu::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}
.ant-dropdown-menu::-webkit-scrollbar-thumb {
  background-color: rgb(0, 0, 0, 0.5);
  border-radius: 16px;
  border: 4px solid var(--gray1);
}
.ant-dropdown-menu::-webkit-scrollbar-track {
  background: white;
}

@media (max-width: 767px) {
  /* Collapse */
  .ant-collapse-expand-icon {
    padding-right: 8px !important;
  }

  /* Pagination */
  .ant-pagination-item {
    border: 0 !important;
    margin-right: 0 !important;
  }

  /* Upload */
  .ant-upload-drag {
    background-color: transparent !important;
    border: 0 !important;
  }
  .ant-upload-btn {
    padding: 0 !important;
  }
  .ant-upload-list {
    display: none !important;
  }

  /* DatePicker */
  .datepicker-rangepicker {
    position: absolute !important;
    top: 0 !important;
    left: 50% !important;
    transform: translate(-50%, 0) !important;
    z-index: 1300 !important;
    overflow: auto !important;
    height: 100% !important;
    width: 100% !important;
  }
  .datepicker-rangepicker .ant-picker-range-wrapper {
    height: 100% !important;
  }
  .datepicker-rangepicker .ant-picker-panel-container {
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
      0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05) !important;
    margin: auto !important;
  }

  .ant-picker-panels {
    display: flex !important;
    flex-direction: column !important;
  }

  .ant-picker-datetime-panel {
    display: flex !important;
    flex-direction: column !important;
  }
  .ant-picker-time-panel {
    display: none !important;
  }
}

/* Buttons */
.disabled-button {
  cursor: not-allowed !important;
  pointer-events: none !important;
  color: var(--gray5);
  border-color: var(--gray4);
  background-color: var(--gray2);
}
.disabled-button:hover {
  cursor: not-allowed !important;
  pointer-events: none !important;
  color: var(--gray5) !important;
  border-color: var(--gray4) !important;
  background-color: var(--gray2) !important;
}
.disabled-button:active {
  cursor: not-allowed !important;
  pointer-events: none !important;
  color: var(--gray5) !important;
  border-color: var(--gray4) !important;
  background-color: var(--gray2) !important;
}

/* Red Buttons */
.red-primary-button {
  color: white;
  border-color: var(--red6);
  background-color: var(--red6);
}
.red-primary-button:hover {
  color: white !important;
  border-color: var(--red5) !important;
  background-color: var(--red5) !important;
}
.red-primary-button:active {
  color: white !important;
  border-color: var(--red7) !important;
  background-color: var(--red7) !important;
}

.red-default-button {
  color: var(--gray10);
  border-color: var(--gray4);
  background-color: white;
}
.red-default-button:hover {
  color: var(--red6) !important;
  border-color: var(--red6) !important;
  background-color: white !important;
}
.red-default-button:active {
  color: var(--red7) !important;
  border-color: var(--red7) !important;
  background-color: white !important;
}

.red-dashed-button {
  color: var(--gray10);
  border-color: var(--gray4);
  border-style: dashed;
  background-color: white;
}
.red-dashed-button:hover {
  color: var(--red6) !important;
  border-color: var(--red6) !important;
  border-style: dashed !important;
  background-color: white !important;
}
.red-dashed-button:active {
  color: var(--red7) !important;
  border-color: var(--red7) !important;
  border-style: dashed !important;
  background-color: white !important;
}

.red-link-button {
  color: var(--red6);
  border-color: transparent;
}
.red-link-button:hover {
  color: var(--red5) !important;
  border-color: transparent !important;
}
.red-link-button:active {
  color: var(--red7) !important;
  border-color: transparent !important;
}

/* Volcano Buttons */
.volcano-primary-button {
  color: white;
  border-color: var(--volcano6);
  background-color: var(--volcano6);
}
.volcano-primary-button:hover {
  color: white !important;
  border-color: var(--volcano5) !important;
  background-color: var(--volcano5) !important;
}
.volcano-primary-button:active {
  color: white !important;
  border-color: var(--volcano7) !important;
  background-color: var(--volcano7) !important;
}

.volcano-default-button {
  color: var(--gray10);
  border-color: var(--gray4);
  background-color: white;
}
.volcano-default-button:hover {
  color: var(--volcano6) !important;
  border-color: var(--volcano6) !important;
  background-color: white !important;
}
.volcano-default-button:active {
  color: var(--volcano7) !important;
  border-color: var(--volcano7) !important;
  background-color: white !important;
}

.volcano-dashed-button {
  color: var(--gray10);
  border-color: var(--gray4);
  border-style: dashed;
  background-color: white;
}
.volcano-dashed-button:hover {
  color: var(--volcano6) !important;
  border-color: var(--volcano6) !important;
  border-style: dashed !important;
  background-color: white !important;
}
.volcano-dashed-button:active {
  color: var(--volcano7) !important;
  border-color: var(--volcano7) !important;
  border-style: dashed !important;
  background-color: white !important;
}

.volcano-link-button {
  color: var(--volcano6);
  border-color: transparent;
}
.volcano-link-button:hover {
  color: var(--volcano5) !important;
  border-color: transparent !important;
}
.volcano-link-button:active {
  color: var(--volcano7) !important;
  border-color: transparent !important;
}

/* Orange Buttons */
.orange-primary-button {
  color: white;
  border-color: var(--orange6);
  background-color: var(--orange6);
}
.orange-primary-button:hover {
  color: white !important;
  border-color: var(--orange5) !important;
  background-color: var(--orange5) !important;
}
.orange-primary-button:active {
  color: white !important;
  border-color: var(--orange7) !important;
  background-color: var(--orange7) !important;
}

.orange-default-button {
  color: var(--gray10);
  border-color: var(--gray4);
  background-color: white;
}
.orange-default-button:hover {
  color: var(--orange6) !important;
  border-color: var(--orange6) !important;
  background-color: white !important;
}
.orange-default-button:active {
  color: var(--orange7) !important;
  border-color: var(--orange7) !important;
  background-color: white !important;
}

.orange-dashed-button {
  color: var(--gray10);
  border-color: var(--gray4);
  border-style: dashed;
  background-color: white;
}
.orange-dashed-button:hover {
  color: var(--orange6) !important;
  border-color: var(--orange6) !important;
  border-style: dashed !important;
  background-color: white !important;
}
.orange-dashed-button:active {
  color: var(--orange7) !important;
  border-color: var(--orange7) !important;
  border-style: dashed !important;
  background-color: white !important;
}

.orange-link-button {
  color: var(--orange6);
  border-color: transparent;
}
.orange-link-button:hover {
  color: var(--orange5) !important;
  border-color: transparent !important;
}
.orange-link-button:active {
  color: var(--orange7) !important;
  border-color: transparent !important;
}

/* Gold Buttons */
.gold-primary-button {
  color: white;
  border-color: var(--gold6);
  background-color: var(--gold6);
}
.gold-primary-button:hover {
  color: white !important;
  border-color: var(--gold5) !important;
  background-color: var(--gold5) !important;
}
.gold-primary-button:active {
  color: white !important;
  border-color: var(--gold7) !important;
  background-color: var(--gold7) !important;
}

.gold-default-button {
  color: var(--gray10);
  border-color: var(--gray4);
  background-color: white;
}
.gold-default-button:hover {
  color: var(--gold6) !important;
  border-color: var(--gold6) !important;
  background-color: white !important;
}
.gold-default-button:active {
  color: var(--gold7) !important;
  border-color: var(--gold7) !important;
  background-color: white !important;
}

.gold-dashed-button {
  color: var(--gray10);
  border-color: var(--gray4);
  border-style: dashed;
  background-color: white;
}
.gold-dashed-button:hover {
  color: var(--gold6) !important;
  border-color: var(--gold6) !important;
  border-style: dashed !important;
  background-color: white !important;
}
.gold-dashed-button:active {
  color: var(--gold7) !important;
  border-color: var(--gold7) !important;
  border-style: dashed !important;
  background-color: white !important;
}

.gold-link-button {
  color: var(--gold6);
  border-color: transparent;
}
.gold-link-button:hover {
  color: var(--gold5) !important;
  border-color: transparent !important;
}
.gold-link-button:active {
  color: var(--gold7) !important;
  border-color: transparent !important;
}

/* Yellow Buttons */
.yellow-primary-button {
  color: white;
  border-color: var(--yellow6);
  background-color: var(--yellow6);
}
.yellow-primary-button:hover {
  color: white !important;
  border-color: var(--yellow5) !important;
  background-color: var(--yellow5) !important;
}
.yellow-primary-button:active {
  color: white !important;
  border-color: var(--yellow7) !important;
  background-color: var(--yellow7) !important;
}

.yellow-default-button {
  color: var(--gray10);
  border-color: var(--gray4);
  background-color: white;
}
.yellow-default-button:hover {
  color: var(--yellow6) !important;
  border-color: var(--yellow6) !important;
  background-color: white !important;
}
.yellow-default-button:active {
  color: var(--yellow7) !important;
  border-color: var(--yellow7) !important;
  background-color: white !important;
}

.yellow-dashed-button {
  color: var(--gray10);
  border-color: var(--gray4);
  border-style: dashed;
  background-color: white;
}
.yellow-dashed-button:hover {
  color: var(--yellow6) !important;
  border-color: var(--yellow6) !important;
  border-style: dashed !important;
  background-color: white !important;
}
.yellow-dashed-button:active {
  color: var(--yellow7) !important;
  border-color: var(--yellow7) !important;
  border-style: dashed !important;
  background-color: white !important;
}

.yellow-link-button {
  color: var(--yellow6);
  border-color: transparent;
}
.yellow-link-button:hover {
  color: var(--yellow5) !important;
  border-color: transparent !important;
}
.yellow-link-button:active {
  color: var(--yellow7) !important;
  border-color: transparent !important;
}

/* Lime Buttons */
.lime-primary-button {
  color: white;
  border-color: var(--lime6);
  background-color: var(--lime6);
}
.lime-primary-button:hover {
  color: white !important;
  border-color: var(--lime5) !important;
  background-color: var(--lime5) !important;
}
.lime-primary-button:active {
  color: white !important;
  border-color: var(--lime7) !important;
  background-color: var(--lime7) !important;
}

.lime-default-button {
  color: var(--gray10);
  border-color: var(--gray4);
  background-color: white;
}
.lime-default-button:hover {
  color: var(--lime6) !important;
  border-color: var(--lime6) !important;
  background-color: white !important;
}
.lime-default-button:active {
  color: var(--lime7) !important;
  border-color: var(--lime7) !important;
  background-color: white !important;
}

.lime-dashed-button {
  color: var(--gray10);
  border-color: var(--gray4);
  border-style: dashed;
  background-color: white;
}
.lime-dashed-button:hover {
  color: var(--lime6) !important;
  border-color: var(--lime6) !important;
  border-style: dashed !important;
  background-color: white !important;
}
.lime-dashed-button:active {
  color: var(--lime7) !important;
  border-color: var(--lime7) !important;
  border-style: dashed !important;
  background-color: white !important;
}

.lime-link-button {
  color: var(--lime6);
  border-color: transparent;
}
.lime-link-button:hover {
  color: var(--lime5) !important;
  border-color: transparent !important;
}
.lime-link-button:active {
  color: var(--lime7) !important;
  border-color: transparent !important;
}

/* Green Buttons */
.green-primary-button {
  color: white;
  border-color: var(--green6);
  background-color: var(--green6);
}
.green-primary-button:hover {
  color: white !important;
  border-color: var(--green5) !important;
  background-color: var(--green5) !important;
}
.green-primary-button:active {
  color: white !important;
  border-color: var(--green7) !important;
  background-color: var(--green7) !important;
}

.green-default-button {
  color: var(--gray10);
  border-color: var(--gray4);
  background-color: white;
}
.green-default-button:hover {
  color: var(--green6) !important;
  border-color: var(--green6) !important;
  background-color: white !important;
}
.green-default-button:active {
  color: var(--green7) !important;
  border-color: var(--green7) !important;
  background-color: white !important;
}

.green-dashed-button {
  color: var(--gray10);
  border-color: var(--gray4);
  border-style: dashed;
  background-color: white;
}
.green-dashed-button:hover {
  color: var(--green6) !important;
  border-color: var(--green6) !important;
  border-style: dashed !important;
  background-color: white !important;
}
.green-dashed-button:active {
  color: var(--green7) !important;
  border-color: var(--green7) !important;
  border-style: dashed !important;
  background-color: white !important;
}

.green-link-button {
  color: var(--green6);
  border-color: transparent;
}
.green-link-button:hover {
  color: var(--green5) !important;
  border-color: transparent !important;
}
.green-link-button:active {
  color: var(--green7) !important;
  border-color: transparent !important;
}

/* Cyan Buttons */
.cyan-primary-button {
  color: white;
  border-color: var(--cyan6);
  background-color: var(--cyan6);
}
.cyan-primary-button:hover {
  color: white !important;
  border-color: var(--cyan5) !important;
  background-color: var(--cyan5) !important;
}
.cyan-primary-button:active {
  color: white !important;
  border-color: var(--cyan7) !important;
  background-color: var(--cyan7) !important;
}

.cyan-default-button {
  color: var(--gray10);
  border-color: var(--gray4);
  background-color: white;
}
.cyan-default-button:hover {
  color: var(--cyan6) !important;
  border-color: var(--cyan6) !important;
  background-color: white !important;
}
.cyan-default-button:active {
  color: var(--cyan7) !important;
  border-color: var(--cyan7) !important;
  background-color: white !important;
}

.cyan-dashed-button {
  color: var(--gray10);
  border-color: var(--gray4);
  border-style: dashed;
  background-color: white;
}
.cyan-dashed-button:hover {
  color: var(--cyan6) !important;
  border-color: var(--cyan6) !important;
  border-style: dashed !important;
  background-color: white !important;
}
.cyan-dashed-button:active {
  color: var(--cyan7) !important;
  border-color: var(--cyan7) !important;
  border-style: dashed !important;
  background-color: white !important;
}

.cyan-link-button {
  color: var(--cyan6);
  border-color: transparent;
}
.cyan-link-button:hover {
  color: var(--cyan5) !important;
  border-color: transparent !important;
}
.cyan-link-button:active {
  color: var(--cyan7) !important;
  border-color: transparent !important;
}

/* Blue Buttons */
.blue-primary-button {
  color: white;
  border-color: var(--blue6);
  background-color: var(--blue6);
}
.blue-primary-button:hover {
  color: white !important;
  border-color: var(--blue5) !important;
  background-color: var(--blue5) !important;
}
.blue-primary-button:active {
  color: white !important;
  border-color: var(--blue7) !important;
  background-color: var(--blue7) !important;
}

.blue-default-button {
  color: var(--gray10);
  border-color: var(--gray4);
  background-color: white;
}
.blue-default-button:hover {
  color: var(--blue6) !important;
  border-color: var(--blue6) !important;
  background-color: white !important;
}
.blue-default-button:active {
  color: var(--blue7) !important;
  border-color: var(--blue7) !important;
  background-color: white !important;
}

.blue-dashed-button {
  color: var(--gray10);
  border-color: var(--gray4);
  border-style: dashed;
  background-color: white;
}
.blue-dashed-button:hover {
  color: var(--blue6) !important;
  border-color: var(--blue6) !important;
  border-style: dashed !important;
  background-color: white !important;
}
.blue-dashed-button:active {
  color: var(--blue7) !important;
  border-color: var(--blue7) !important;
  border-style: dashed !important;
  background-color: white !important;
}

.blue-link-button {
  color: var(--blue6);
  border-color: transparent;
}
.blue-link-button:hover {
  color: var(--blue5) !important;
  border-color: transparent !important;
}
.blue-link-button:active {
  color: var(--blue7) !important;
  border-color: transparent !important;
}

/* Geekblue Buttons */
.geekblue-primary-button {
  color: white;
  border-color: var(--geekblue6);
  background-color: var(--geekblue6);
}
.geekblue-primary-button:hover {
  color: white !important;
  border-color: var(--geekblue5) !important;
  background-color: var(--geekblue5) !important;
}
.geekblue-primary-button:active {
  color: white !important;
  border-color: var(--geekblue7) !important;
  background-color: var(--geekblue7) !important;
}

.geekblue-default-button {
  color: var(--gray10);
  border-color: var(--gray4);
  background-color: white;
}
.geekblue-default-button:hover {
  color: var(--geekblue6) !important;
  border-color: var(--geekblue6) !important;
  background-color: white !important;
}
.geekblue-default-button:active {
  color: var(--geekblue7) !important;
  border-color: var(--geekblue7) !important;
  background-color: white !important;
}

.geekblue-dashed-button {
  color: var(--gray10);
  border-color: var(--gray4);
  border-style: dashed;
  background-color: white;
}
.geekblue-dashed-button:hover {
  color: var(--geekblue6) !important;
  border-color: var(--geekblue6) !important;
  border-style: dashed !important;
  background-color: white !important;
}
.geekblue-dashed-button:active {
  color: var(--geekblue7) !important;
  border-color: var(--geekblue7) !important;
  border-style: dashed !important;
  background-color: white !important;
}

.geekblue-link-button {
  color: var(--geekblue6);
  border-color: transparent;
}
.geekblue-link-button:hover {
  color: var(--geekblue5) !important;
  border-color: transparent !important;
}
.geekblue-link-button:active {
  color: var(--geekblue7) !important;
  border-color: transparent !important;
}

/* Purple Buttons */
.purple-primary-button {
  color: white;
  border-color: var(--purple6);
  background-color: var(--purple6);
}
.purple-primary-button:hover {
  color: white !important;
  border-color: var(--purple5) !important;
  background-color: var(--purple5) !important;
}
.purple-primary-button:active {
  color: white !important;
  border-color: var(--purple7) !important;
  background-color: var(--purple7) !important;
}

.purple-default-button {
  color: var(--gray10);
  border-color: var(--gray4);
  background-color: white;
}
.purple-default-button:hover {
  color: var(--purple6) !important;
  border-color: var(--purple6) !important;
  background-color: white !important;
}
.purple-default-button:active {
  color: var(--purple7) !important;
  border-color: var(--purple7) !important;
  background-color: white !important;
}

.purple-dashed-button {
  color: var(--gray10);
  border-color: var(--gray4);
  border-style: dashed;
  background-color: white;
}
.purple-dashed-button:hover {
  color: var(--purple6) !important;
  border-color: var(--purple6) !important;
  border-style: dashed !important;
  background-color: white !important;
}
.purple-dashed-button:active {
  color: var(--purple7) !important;
  border-color: var(--purple7) !important;
  border-style: dashed !important;
  background-color: white !important;
}

.purple-link-button {
  color: var(--purple6);
  border-color: transparent;
}
.purple-link-button:hover {
  color: var(--purple5) !important;
  border-color: transparent !important;
}
.purple-link-button:active {
  color: var(--purple7) !important;
  border-color: transparent !important;
}

/* Magenta Buttons */
.magenta-primary-button {
  color: white;
  border-color: var(--magenta6);
  background-color: var(--magenta6);
}
.magenta-primary-button:hover {
  color: white !important;
  border-color: var(--magenta5) !important;
  background-color: var(--magenta5) !important;
}
.magenta-primary-button:active {
  color: white !important;
  border-color: var(--magenta7) !important;
  background-color: var(--magenta7) !important;
}

.magenta-default-button {
  color: var(--gray10);
  border-color: var(--gray4);
  background-color: white;
}
.magenta-default-button:hover {
  color: var(--magenta6) !important;
  border-color: var(--magenta6) !important;
  background-color: white !important;
}
.magenta-default-button:active {
  color: var(--magenta7) !important;
  border-color: var(--magenta7) !important;
  background-color: white !important;
}

.magenta-dashed-button {
  color: var(--gray10);
  border-color: var(--gray4);
  border-style: dashed;
  background-color: white;
}
.magenta-dashed-button:hover {
  color: var(--magenta6) !important;
  border-color: var(--magenta6) !important;
  border-style: dashed !important;
  background-color: white !important;
}
.magenta-dashed-button:active {
  color: var(--magenta7) !important;
  border-color: var(--magenta7) !important;
  border-style: dashed !important;
  background-color: white !important;
}

.magenta-link-button {
  color: var(--magenta6);
  border-color: transparent;
}
.magenta-link-button:hover {
  color: var(--magenta5) !important;
  border-color: transparent !important;
}
.magenta-link-button:active {
  color: var(--magenta7) !important;
  border-color: transparent !important;
}

/* Gray Buttons */
.gray-primary-button {
  color: white;
  border-color: var(--gray6);
  background-color: var(--gray6);
}
.gray-primary-button:hover {
  color: white !important;
  border-color: var(--gray5) !important;
  background-color: var(--gray5) !important;
}
.gray-primary-button:active {
  color: white !important;
  border-color: var(--gray7) !important;
  background-color: var(--gray7) !important;
}

.gray-default-button {
  color: var(--gray10);
  border-color: var(--gray4);
  background-color: white;
}
.gray-default-button:hover {
  color: var(--gray6) !important;
  border-color: var(--gray6) !important;
  background-color: white !important;
}
.gray-default-button:active {
  color: var(--gray7) !important;
  border-color: var(--gray7) !important;
  background-color: white !important;
}


@media print {
  body * {
    visibility: hidden;
  }
  .print-none * {
    display: none;
  }
  .print-pdf * {
    visibility: visible !important;
  }
}
